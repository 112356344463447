#banner-video {
  height: 690px;
  position: relative;
  z-index: 5;
  overflow: hidden;
  
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    z-index: 1;
  }
  
  &:before {
    display: block;
    content: "";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .65;
    background: $brand-secondary url("../images/pattern.png") repeat center top;
  }
  
  &:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 455px;
    background: url("../images/banner-shadow.png") repeat-x center top;
  }
  
  .banner-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 100px 0 0;
    display: flex;
    align-items: center;
    
    > div {
      flex: 1;
      width: 100%;
    }
    
    .container {
      > div {
        max-width: 940px;
        margin: auto;
        line-height: 2;
        color: #fff;
        font-size: 16px;
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    height: 600px;
  }
  
  @media (max-width: $grid-float-breakpoint-max) {
    height: 500px;
    
    .banner-text {
      padding: 0;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .banner-text {
      padding: 0;
      
      .container {
        > div {
          font-size: 14px;
          line-height: $line-height-base;
        }
      }
    }
  }
  
  @media (max-width: $grid-float-breakpoint-max) {
    height: 400px;
  }
}

#banner {
  height: 424px;
  background: url("../images/default-banner.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  
  &:before {
    display: block;
    content: "";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .65;
    background: $brand-secondary url("../images/pattern.png") repeat center top;
  }
  
  &:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 455px;
    background: url("../images/banner-shadow.png") repeat-x center top;
  }
  
  .banner-content {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
    z-index: 10;
  }
  
  @media (max-width: $screen-sm-max) {
    height: 300px;
    
    .banner-content {
      top: 150px;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    height: 200px;
    
    .banner-content {
      top: 50px;
    }
  }
}

#cta {
  position: relative;
  z-index: 20;
  background: $brand-primary;
  color: #fff;
  
  .cta-title {
    float: left;
    width: calc(100% - 460px);
    padding: 35px 0;
  }
  
  .cta-box {
    position: absolute;
    top: -74px;
    right: $grid-gutter-width/2;
    width: 430px;
    box-shadow: 2px 5px 68px rgba(1,2,2,.55);
    background: $brand-primary;
    padding: $grid-gutter-width;
    text-align: center;
    
    h3 {
      margin-bottom: 20px;
    }
  }
  
  div.wpcf7 {
    .form-group {
      .form-control {
        color: #fff;
        border-bottom-color: #fff;
        
        @include input-placeholder {
          color: #fff;
        }
      }
      
      span.wpcf7-not-valid-tip {
        color: inherit;
      }
    }
    
    .form-submit {
      padding: 10px 0 0;
    }
    
    .form-submit-button {
      border: solid 1px #fff;
      background: transparent;
      outline: none;
      color: #fff;
      appearance: none;
      cursor: pointer;
      font-family: $font-family-custom;
      font-weight: 300;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 22px;
      padding: 10px 40px;
      transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
      
      &:hover {
        color: $brand-primary;
        background: #fff;
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .cta-title {
      float: none;
      width: auto;
      text-align: center;
    }
    
    .cta-box {
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      margin: 0 auto -30px;
    }
  }
}

.homepage-content {
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
  .text-column {
    width: 430px;
    order: 2;
  }
  
  .images-carousel-column {
    position: relative;
    max-width: 550px;
    margin-right: $grid-gutter-width;
  }
  
  .images-carousel {
    position: relative;
    box-shadow: 2px 5px 68px rgba(1,2,2,.55);
  }
  
  .slick-dots-wrapper {
    z-index: 0;
    position: absolute;
    top: 100%;
    margin-top: -30px;
    left: 10px;
    right: 10px;
    text-align: center;
    
    .slick-dots {
      display: inline-block;
      vertical-align: top;
      background: #fff;
      margin: 0;
      font-size: 0;
      padding: 13px 13px 30px;
      list-style: none;
      
      li {
        display: inline-block;
        vertical-align: top;
        padding: 7px;
        
        &.slick-active {
          button {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
      
      button {
        appearance: none;
        width: 16px;
        height: 16px;
        padding: 0;
        outline: none;
        border-radius: 0;
        border: solid 1px rgba($brand-primary, .5);
        background: #fff;
        transition: all .3s ease-in-out;
        
        &:hover {
          background: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    display: block;
    
    .text-column {
      width: auto;
    }
    
    .images-carousel-column {
      margin: 50px auto 0;
    }
  }
}

.internal-page-content {
  justify-content: space-between;
  display: flex;
  
  .text-wrapper {
    flex: 1;
    padding: 80px 0;
  }
  
  .sidebar-image {
    width: 460px;
    padding-left: 30px;
    align-self: flex-end;
  }
  
  @media (max-width: $screen-sm-max) {
    display: block;
    
    .sidebar-image {
      max-width: 320px;
      margin: auto;
      width: auto;
      
      img {
        margin: auto;
      }
    }
  }
}

.services-page-content {
  padding: 90px 0 70px;
  
  .text-wrapper {
    max-width: 840px;
    margin: auto;
  }
  
  .services-list {
    @include clearfix;
    margin: 50px -10px 0;
    
    .service {
      float: left;
      padding: 0 10px 20px;
      width: 50%;
      
      &:nth-child(2n+1) {
        clear: left;
      }
      
      a {
        display: block;
        text-decoration: none;
        position: relative;
        background: $brand-primary;
        box-shadow: 2px 5px 20px rgba(1,2,2,.27);
        
        img {
          width: 100%;
        }
        
        span {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: rgba($brand-secondary, .78);
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: 2px;
          font-family: $font-family-custom;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          padding: 20px 50px 20px 30px;
          transition: all .3s ease-in-out;
          
          &:after {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            right: 30px;
            width: 12px;
            height: 22px;
            background: url("../images/services-arrow.png") no-repeat center;
            background-size: 12px auto;
            margin-top: -11px;
          }
        }
        
        &:hover {
          span {
            background: $brand-primary;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .services-list {
      max-width: 530px;
      margin: 50px auto 0;
      
      .service {
        width: auto;
        float: none;
        padding: 0;
        
        & + .service {
          margin-top: 20px;
        }
      }
    }
  }
  
  @media (max-width: $screen-xs) {
    .services-list {
      .service {
        a {
          span {
            padding: 15px 40px 15px 20px;
            font-size: 16px;
            line-height: 20px;
            
            &:after {
              right: 20px;
            }
          }
        }
      }
    }
  }
}

.service-detail-page-content {
  justify-content: space-between;
  display: flex;
  
  .text-wrapper {
    flex: 1;
    padding: 80px 0;
  }
  
  .other-services {
    width: 340px;
    padding: 110px 0 80px 30px;
    
    h4 {
      margin-bottom: 1em;
    }
    
    .service {
      display: block;
      text-decoration: none;
      position: relative;
      background: $brand-primary;
      box-shadow: 2px 5px 20px rgba(1,2,2,.27);

      img {
        width: 100%;
      }

      span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba($brand-secondary, .78);
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        font-family: $font-family-custom;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 30px 10px 20px;
        transition: all .3s ease-in-out;

        &:after {
          display: block;
          content: "";
          position: absolute;
          top: 50%;
          right: 20px;
          width: 8px;
          height: 13px;
          background: url("../images/services-arrow-small.png") no-repeat center;
          background-size: 8px auto;
          margin-top: -6px;
        }
      }

      &:hover {
        span {
          background: $brand-primary;
        }
      }
      
      & + .service {
        margin-top: 10px;
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    display: block;
    
    .other-services {
      width: auto;
      max-width: 530px;
      margin: auto;
      padding: 0 0 50px;
    }
  }
}

.contact-page-content {
  padding: 90px 0 40px;
  
  .text-wrapper {
    max-width: 600px;
  }
  
  h2 {
    letter-spacing: 1px;
    font-family: $font-family-sans-serif;
    font-size: 30px;
  }
  
  .social-links {
    font-size: 24px;
    
    &:before {
      display: block;
      content: "";
      width: 28px;
      height: 7px;
      background: $brand-secondary;
      margin: 0 0 25px;
    }
    
    a {
      display: inline-block;
      vertical-align: top;
      width: 46px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border-radius: 100%;
      background: $brand-secondary;
      color: #fff;
      text-decoration: none;
      
      &:hover {
        background: $brand-primary;
      }
    }
  }
}

#free-quote {
  background: #ebebeb;
  padding: 70px 0;
  
  div.wpcf7 {
    .form-group {
      margin: 0 0 16px;
    }
    
    .form-submit {
      .button {
        display: block;
        width: 100%;
      }
    }
  }
}