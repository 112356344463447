body#tinymce {
  margin: 12px !important;
  
  #{$headings} {
    font-weight: 300 !important;
    
    strong, b {
      font-weight: bold !important;
    }
  }
  
  img {
    box-shadow: 2px 5px 59px rgba(1,2,2,.43);
  }
  
  .button {
    &[data-mce-selected] {
      padding: 10px 30px;
      margin: 0;
    }
  }
}