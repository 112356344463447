#footer {
  background: url("../images/footer-background.jpg") no-repeat center top;
  background-size: cover;
  position: relative;
  padding: 50px 0;
  color: #fff;
  
  a {
    color: inherit;
  }
  
  &:before {
    position: absolute;
    display: block;
    content: "";
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($brand-primary, .83);
  }
  
  &:after {
    position: absolute;
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 43px;
    top: 0;
    left: 0;
    background: url("../images/footer-shadow.png") repeat-x center top;
  }
  
  .social-links {
    text-align: center;
    margin: 25px 0 35px;
    font-size: 28px;
    
    a {
      display: inline-block;
      vertical-align: top;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 100%;
      color: $brand-primary;
      text-decoration: none;
      background: #fff;
      
      &:hover {
        background: $brand-secondary;
        color: #fff;
      }
    }
  }
  
  hr {
    border-color: #fff;
  }
  
  h2 {
    letter-spacing: 2px;
  }
}