// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

div.wpcf7 {
  .form-group {
    margin: 0 0 10px;
    
    .form-control {
      border: none;
      border-bottom: solid 1px $brand-primary;
      padding: 5px 0;
      outline: none;
      color: $brand-primary;
      font-family: $font-family-custom;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 2px;
      background: transparent;
      box-shadow: none;
      vertical-align: top;
      margin: 0;
      text-align: inherit;
      
      @include input-placeholder {
        font-family: $font-family-custom;
        font-weight: 300;
        opacity: 1;
        color: $brand-primary;
      }
    }
    
    span.wpcf7-not-valid-tip {
      font-size: 12px;
      margin: 3px 0 0;
      font-family: $font-family-custom;
      letter-spacing: 1px;
    }
  }
  
  .form-submit {
    .ajax-loader {
      @extend .fa;
      @extend .fa-spin;
      @extend .fa-spinner;
      
      background: none !important;
      margin: 0 auto;
      width: 16px;
      line-height: 1;
      font-size: 20px;
      display: none;
      
      &.is-active {
        margin: 10px auto 0;
        display: block;
      }
    }
  }
  
  div.wpcf7-response-output {
    padding: 10px;
    margin: 15px 0 0;
  }
}