// Glyphicons font path
$icon-font-path:              "../fonts/";

// Grid settings
$main-sm-columns:             12;
$sidebar-sm-columns:          4;
$grid-gutter-width:           30px;
$container-large-desktop:     (1080px + $grid-gutter-width);
$screen-lg:                   $container-large-desktop + 30px;

// Colors
$brand-primary:               #3d7123;
$brand-secondary:             #3c2313;
$text-color:                  #464646;


// Fonts
$font-size-base:              14px;
$font-family-sans-serif:      "Open Sans", sans-serif;
$font-family-custom:          "Roboto Condensed", sans-serif;
$font-family-base:            $font-family-sans-serif;
$line-height-base:            1.571428;
$headings:                    'h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6';
$headings-font-weight:        300;
$headings-font-family:        $font-family-custom;
$headings-line-height:        1;
$font-size-h1:                60px;
$font-size-h2:                40px;
$font-size-h3:                20px;
$font-size-h4:                18px;
$font-size-h5:                16px;
$font-size-h6:                14px;

// Forms
$border-radius-base:          0;
$input-border:                #c9c9c9;