#page {
  overflow: hidden;
  position: relative;
}

a {
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
}

ul, ol {
  margin-left: 0;
  padding-left: 1.5em;
}

#{$headings} {
  text-transform: uppercase;
  margin: 1em 0 .5em;
  
  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  strong, b {
    font-weight: bold;
  }
}

h1, .h1 {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  
  @media (max-width: $screen-xs-max) {
    font-size: 48px;
  }
  
  @media (max-width: $screen-xs) {
    font-size: 40px;
  }
}

h2, .h2 {
  letter-spacing: 4px;
  
  & + h1 {
    margin-top: -10px;
  }
  
  @media (max-width: $screen-xs-max) {
    font-size: 30px;
    letter-spacing: 3px;
  }
  
  @media (max-width: $screen-xs) {
    font-size: 26px;
  }
}

h3, .h3 {
  letter-spacing: 3px;
}

h4, .h4 {
  letter-spacing: 1px;
}

h5, .h5 {
}

h6, .h6 {
}

p, ul, ol {
  margin-bottom: 1em;
  
  img {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

hr {
  border: none;
  border-top: solid 1px $brand-primary;
  margin: 30px 0;
}

blockquote {
  border: none;
  padding-left: 0;
  margin-left: 0;
}

p, ul, ol, blockquote, #{$headings}, hr {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.display-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  
  .display-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.slick-slider {
  .slick-slide {
    &:focus,
    &:active,
    &:focus:active {
      outline: none;
    }
  }
}

@mixin input-placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}


.modal-popup {
  .modal-content {
    padding: 30px;
    box-shadow: none;
    margin: 50px 0 0;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    line-height: 30px;
    opacity: 1;
    color: $brand-secondary;
    
    &:hover {
      color: $brand-primary;
    }
  }
}

.text-wrapper {
  img {
    box-shadow: 2px 5px 59px rgba(1,2,2,.43);
  }
}

.text-primary,
.text-green {
  color: $brand-primary;
}

.text-secondary,
.text-brown {
  color: $brand-secondary;
}

.text-wrapper {
  @include clearfix;
}