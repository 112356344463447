.button {
  @extend .btn;
  background: $brand-primary;
  color: #fff;
  font-family: $font-family-custom;
  font-size: 18px;
  font-weight: 300;
  text-align: none;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  line-height: 24px;
  white-space: normal;
  padding: 10px 30px;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
  
  &:hover {
    background: $brand-secondary;
    color: #fff;
  }
}