#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
  border-bottom: solid 1px #ebebeb;
  transition: all .3s ease-in-out;
  
  body.scrolled & {
    background: rgba($brand-secondary, .9);
  }
  
  body.admin-bar & {
    top: 32px;
  }
  
  @media (min-width: $grid-float-breakpoint) {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      &:before,
      &:after {
        display: none;
      }
      
      .brand {
        width: 212px;
        transition: all .3s ease-in-out;
        
        a {
          display: block;
        }
        
        body.scrolled & {
          width: 132px;
        }
      }
      
      #navigation {
        margin: 0;
        padding: 0;
        
        .menu {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style: none;
          
          > li {
            display: flex;
            align-items: center;
            position: relative;
            
            > a {
              white-space: nowrap;
              display: block;
              line-height: 22px;
              padding: 5px 13px;
              text-transform: uppercase;
              text-decoration: none;
              color: #fff;
              font-weight: bold;
              font-size: 16px;
              
              @media (max-width: $screen-sm-max) {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
            
            & + li {
              margin-left: 20px;
              
              @media (max-width: $screen-sm-max) {
                margin-left: 10px;
              }
            }
            
            &.button-link {
              > a {
                line-height: 29px;
                padding: 40px 30px;
                background: $brand-primary;
                color: #fff;
                transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, padding .3s ease-in-out;
                
                &:hover {
                  background: darken($brand-secondary, 5%);
                }
                
                body.scrolled & {
                  padding-top: 20px;
                  padding-bottom: 20px;
                }
                
                @media (max-width: $screen-sm-max) {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
            
            .sub-menu {
              position: absolute;
              top: 100%;
              left: 0;
              font-family: $font-family-custom;
              background: $brand-secondary;
              display: block;
              list-style: none;
              padding: 10px;
              margin: -10px 0 0;
              min-width: 160px;
              opacity: 0;
              visibility: hidden;
              transition: all .3s ease-in-out;
              border: solid 1px #fff;
              
              li {
                & + li {
                  margin-top: 10px;
                }
                
                &.active {
                  > a {
                    color: $brand-secondary;
                    background: $brand-primary;
                  }
                }
              }
              
              a {
                display: block;
                text-decoration: none;
                white-space: nowrap;
                color: #fff;
                padding: 5px 10px;
                font-size: 16px;
                line-height: 24px;
                
                &:hover {
                  color: $brand-secondary;
                  background: $brand-primary;
                }
              }
            }
            
            &.active:not(.button-link),
            &:hover:not(.button-link) {
              > a {
                background: $brand-primary;
                color: $brand-secondary;
              }
            }
          }
          
          li {
            &:hover {
              > .sub-menu {
                margin: 0;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: $grid-float-breakpoint-max) {
    position: relative;
    background: $brand-secondary;
    top: 0;
    
    body.admin-bar & {
      top: 0;
    }
    
    body.scrolled & {
      background: $brand-secondary;
    }
    
    .brand {
      padding: 15px 0;
      float: left;
      width: 132px;
      max-width: calc(100% - 60px);
    }
    
    .navbar-toggle {
      float: right;
      margin: 20px 0 10px;
      border: solid 1px #fff;
      
      .icon-bar {
        background: #fff;
      }
    }
    
    #navigation {
      margin: 0;
      padding: 0;
      clear: both;
      box-shadow: none;
      border: none;
      
      .menu {
        color: #fff;
        display: block;
        margin: 0;
        padding: 20px 0;
        font-family: $font-family-custom;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 1px;
        border-top: solid 1px #fff;
        
        > li {
          display: block;
          
          > a {
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          
          &.button-link {
            a {
              color: #fff;
              background: $brand-primary;
              padding: 5px;
              text-align: center;
            }
          }
        }
        
        a {
          padding: 5px 10px;
          display: block;
          color: #fff;
          text-decoration: none;
          
          &:hover {
            color: $brand-secondary;
            background: $brand-primary;
          }
        }
        
        li {
          & + li {
            margin-top: 5px;
          }
          
          &.active:not(.button-link) {
            > a {
              color: $brand-secondary;
              background: $brand-primary;
            }
          }
        }
        
        .sub-menu {
          list-style: none;
        }
      }
    }
  }
}